.toast {
  color: #fff;
  border-radius: 8px !important;
  padding: 15px !important;
  display: flex;
  cursor: default;
  margin: 5px !important;
  justify-content: flex-start;
  align-content: center;
}

.toast-default {
  color: #fff !important;
  cursor: pointer;
}

.toast-success {
  color: #4cae4c !important;
  border: 1px solid #4cae4c;
  cursor: pointer;
}

.toast-success-variant {
  color: #fff !important;
  border: 1px solid #4cae4c;
  cursor: pointer;
}

.toast-error {
  cursor: pointer;
  color: #d8544f !important;
  border: 1px solid #d8544f!important;
}

.toast-error-variant {
  color: #d8544f!important;
  border: 1px solid #d8544f!important;
  cursor: pointer;
}

.toast-info {
  cursor: pointer!important;
}

.toast-info-variant {
  color: #2a10ab!important;
  border: 4px solid #17399e!important;
  cursor: pointer;
}

.toast-loading-info {
  background: linear-gradient(45deg, #0950ad, #2071eb);
}

.toast-update {
  background: linear-gradient(45deg, #007bff 30%, #5f9efc 90%);
  cursor: pointer;
}
